import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addCart } from "../redux/action"; // Ensure this path is correct
import Loading from "./Loading"; // Ensure this path is correct
import { collection } from "firebase/firestore";
import { db } from "../firebaseConfig"; // Ensure this path is correct
import "./Products.css";
import productsData from "../products.json";

export default function Products() {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [quantities, setQuantities] = useState({});
  const [totals, setTotals] = useState({});
  const productsRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const cartItems = useSelector((state) => state.handleCart.cart);

  useEffect(() => {
    const productsCollection = collection(db, "products");

    // Set up a real-time listener
    // const unsubscribe = onSnapshot(productsCollection, (snapshot) => {
    //   const productsList = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    //   setData(productsList);
    //   setFilter(productsList);
    //   setLoading(false);
    // });

    setFilter(productsData);
    // Clean up the listener on component unmount
    // return () => unsubscribe();
  }, []);

  const handleQuantityChange = (product, quantity) => {
    const validQuantity = quantity < 0 ? 0 : quantity; // Ensure quantity is non-negative
    const updatedQuantities = { ...quantities, [product.id]: validQuantity };
    const updatedTotals = {
      ...totals,
      [product.id]: product.price * validQuantity,
    };
    setQuantities(updatedQuantities);
    setTotals(updatedTotals);
    dispatch(addCart(product, validQuantity));
  };

  const handleQuantityIncrement = (product) => {
    const newQuantity = (quantities[product.id] || 0) + 1;
    handleQuantityChange(product, newQuantity);
  };

  const handleQuantityDecrement = (product) => {
    const newQuantity = (quantities[product.id] || 0) - 1;
    handleQuantityChange(product, newQuantity);
  };

  const calculateGrandTotal = () => {
    return Object.values(totals).reduce((acc, curr) => acc + curr, 0);
  };

  const filterProduct = (category) => {
    const updatedList = data.filter((product) => product.category === category);
    setFilter(updatedList);
  };

  const headings = [
    "SINGLE SOUND CRACKERS",
    "SPARKLERS",
    "TWINKLING STARS",
    "PENCIL",
    "GROUND CHAKKAR",
    "FLOWER POTS",
    "KIDS SPECIAL",
    "COLOUR MATCHES",
    "ATOM BOMBS",
    "ROCKETS",
    "ELECTRIC CRACKERS",
    "GARLAND CRACKERS",
    "GROUND FOUNTAIN",
    "REPEATING MULTICOLOUR SHOTS",
    "AERIAL FANCY",
    "VADIVEL SPECIAL 20% DISCOUNT",
    "BULLETS 2.0 CRACKERS SPECIAL"
  ];

  const ShowProducts = () => {
    const rowsWithHeadings = [];
    let index = 0;
    let headingIndex = 0;

    while (index < filter.length) {
      // Add heading row every 15 rows
      if (headingIndex < headings.length && index % 15 === 0) {
        rowsWithHeadings.push({
          id: `heading-${headingIndex}`,
          heading: true,
          text: headings[headingIndex]
        });
        headingIndex++;
      }

      // Add up to 15 rows of product data
      for (let i = 0; i < 15 && index < filter.length; i++) {
        rowsWithHeadings.push(filter[index]);
        index++;
      }
    }

    return (
      <>
        <div className="buttons">
          <button
            className="btn btn-outline-dark"
            onClick={() => setFilter(data)}
          >
            All
          </button>
          <button
            className="btn btn-outline-dark"
            onClick={() => filterProduct("Rockets")}
          >
            Rockets
          </button>
          <button
            className="btn btn-outline-dark"
            onClick={() => filterProduct("Sparklers")}
          >
            Sparklers
          </button>
          <button
            className="btn btn-outline-dark"
            onClick={() => filterProduct("Gift pack")}
          >
            Gift pack
          </button>
          <button
            className="btn btn-outline-dark"
            onClick={() => filterProduct("Bombs")}
          >
            Bombs
          </button>
        </div>
        <div className="row m-0 p-0">
          <div className="col-12 p-0">
            <div className="text-center">
              <h2 className="total-heading">
                Grand Total: ₹{calculateGrandTotal()}
              </h2>
              <button
                className="btn btn-primary"
                onClick={() => history.push("/Checkout")}
              >
                Go to Cart
              </button>
            </div>
            <table className="table table-bordered table-bg">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Product Name</th>
                  <th className="content-cell">Content</th>
                  <th>Actual Price</th>
                  <th>Amount</th>
                  <th>Quantity</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {rowsWithHeadings.map((item, index) =>
                  item.heading ? (
                    <tr key={item.id} style={{ backgroundColor: 'Red' }}>
                      <td colSpan="7" style={{ color: 'Red', textAlign: 'center', fontWeight: 'bold' }}>
                        {item.text}
                      </td>
                    </tr>
                  ) : (
                    <tr key={item.id}>
                      <td data-label="Image">
                        <img
                          src={item.image}
                          className="product-img-table"
                          alt={item.title}
                        />
                      </td>
                      <td data-label="Product Name">{item.title}</td>
                      <td data-label="Content" className="content-cell">
                        1 box
                      </td>{" "}
                      <td data-label="Actual Price">₹{item.price}</td>
                      <td data-label="Amount">₹{item.price}</td>
                      <td data-label="Quantity">
                        <div className="quantity-controls">
                          <button
                            className="quantity-btn"
                            onClick={() => handleQuantityDecrement(item)}
                          >
                            -
                          </button>
                          <input
                            type="number"
                            value={quantities[item.id] || 0}
                            min="0"
                            onChange={(e) =>
                              handleQuantityChange(
                                item,
                                parseInt(e.target.value, 10)
                              )
                            }
                            className="quantity-input"
                          />
                          <button
                            className="quantity-btn"
                            onClick={() => handleQuantityIncrement(item)}
                          >
                            +
                          </button>
                        </div>
                      </td>
                      <td data-label="Total">₹{totals[item.id] || 0}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="container my-5 py-5">
      {location.pathname === "/products" && (
        <div className="row m-0">
          <div className="col-12 mb-6">
            <div className="text-center">
              <h1
                className="display-6 fw-bolder animated-section"
                ref={productsRef}
              >
                Our Products
              </h1>
            </div>
            <hr />
          </div>
        </div>
      )}
      <div className="row justify-content-center m-0">
        {loading ? <Loading /> : <ShowProducts />}
      </div>
    </div>
  );
}
